import styled from "styled-components";
import { ReactComponent as HomeSVGImage } from "../asset/image/dolapo.svg";
import { TextAlign, TextStyle } from "../props/enums";
import { DolapoTextWithoutUnderline2 } from "../components/DolapoTextWithoutUnderline";

const HomeSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;
  position: relative;
  z-index: 1;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export interface HomeImageProp {
  src?: string | undefined;
  onClick?: () => void | undefined;
}

const HomeImageContainer = styled.div`
  margin-left: 100px;
  flex: 1;
  text-align: right;

  @media (max-width: 800px) {
    flex: 0;
    text-align: center;
    width: 0px;
    height: 0px;
  }
`;
const HomeImage = styled(HomeSVGImage)<HomeImageProp>`
  margin-left: 0px;
  width: 650px;
  height: 602px;

  @media (max-width: 1020px) {
    width: 650px;
    height: 702px;
    margin-left: 130px;
  }

  @media (max-width: 800px) {
    width: 0px;
    height: 0px;
  }
`;

const LinkedInSocial = styled.img<HomeImageProp>`
  position: absolute;
  right: 40px;
  top: 256px;
  z-index: 2;

  @media (max-width: 1020px) {
    width: 0px;
    height: 0px;
  }
`;

const GitHubSocial = styled.img<HomeImageProp>`
  position: absolute;
  right: 40px;
  top: 314px;
  z-index: 2;

  @media (max-width: 1020px) {
    width: 0px;
    height: 0px;
  }
`;

const TwitterSocial = styled.img<HomeImageProp>`
  position: absolute;
  right: 40px;
  top: 372px;
  z-index: 2;

  @media (max-width: 1020px) {
    width: 0px;
    height: 0px;
  }
`;

const HomeCopyContainer = styled.div`
  flex: 1;
  margin-top: 150px;
  margin-right: 160px;
  color: white;

  @media (max-width: 800px) {
    display; block;
    margin-bottom: 30px;
    width: 100%;
  }
`;
const HomeCopy = styled(DolapoTextWithoutUnderline2)`
  margin-right: 50px;
  text-align: left;
  color: white;

  @media (max-width: @screen-sm-min) {
    margin-right: 200px;
  }

  @media (max-width: @screen-sm-min) {
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
  }
`;

const HomePattern = styled.img<HomeImageProp>`
  position: absolute;
  top: 280px;
  left: 80px;
  width: 100%;
  z-index: -1;

  @media (max-width: @screen-sm-min) {
    top: -300px;
  }
`;

const UnderWorks = styled.h4`
  top: 70px;
  color: red;
  text-align: center;
`;

const handleLinkedInClick = (url: string) => {
  window.open(url);
};

const Home = () => {
  return (
    <>
      <UnderWorks> Under Construction</UnderWorks>
      <LinkedInSocial
        src={require("../asset/image/linkedin-light.svg").default}
        onClick={() =>
          handleLinkedInClick(
            "https://www.linkedin.com/in/dolapo-olakanmi-3a2bb2130/"
          )
        }
      />
      <GitHubSocial
        src={require("../asset/image/github-light.svg").default}
        onClick={() => handleLinkedInClick("https://github.com/D-Ordnance")}
      />
      <TwitterSocial
        src={require("../asset/image/twitter-light.svg").default}
        onClick={() => handleLinkedInClick("https://x.com/DolapoOlakanmi")}
      />
      <HomeSection>
        <HomeImageContainer>
          <HomeImage />
        </HomeImageContainer>
        <HomeCopyContainer>
          <HomeCopy
            content="Mobile"
            size={50}
            color="#2b2b2b"
            marginBottom={0}
            underline={false}
            fontWeight={TextStyle.BOLD}
            hasMarginBottom={false}
            textalign={TextAlign.CENTER}
          />
          <HomeCopy
            content=" Engineer"
            size={50}
            color="#6E89BB"
            marginBottom={0}
            underline={false}
            fontWeight={TextStyle.BOLD}
            hasMarginBottom={false}
            textalign={TextAlign.CENTER}
          />
          <br />
          <HomeCopy
            content="Hi, I'm Dolapo, a software engineer with nearly a decade of experience building mobile applications. 
            Feel free to reach out!"
            size={20}
            color="#2b2b2b"
            marginBottom={0}
            underline={false}
            fontWeight={TextStyle.NORMAL}
            hasMarginBottom={false}
            textalign={TextAlign.CENTER}
          />
          {/* <Button>View my Skills{" >>>"}</Button> */}
        </HomeCopyContainer>
        <HomePattern src={require("../asset/image/home-pattern.svg").default} />
      </HomeSection>
    </>
  );
};

export default Home;
