import styled from "styled-components";
import { DolapoTextProp, DolapoTextStyleProp } from "../props/interface";
import { TextAlign, TextStyle } from "../props/enums";

const DolapoTextTag = styled.p<DolapoTextStyleProp>`
  font-family: "LeagueSpartan", "Helvetica, InriaSans";
  display: ${({ displayBlock }) =>
    !displayBlock || displayBlock !== undefined ? `inline` : `block`};
  text-align: ${({ textalign }) =>
    (textalign === TextAlign.CENTER && `center`) ||
    (textalign === TextAlign.RIGHT && `right`) ||
    (textalign === TextAlign.LEFT && `left`)};
  font-weight: ${({ fontWeight }) =>
    (fontWeight === TextStyle.NORMAL && `normal`) ||
    (fontWeight === TextStyle.SEMIBOLD && `500`) ||
    (fontWeight === TextStyle.BOLD && `bold`)};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => `${color}`};
  margin-bottom: ${({ hasmarginbottom, marginbottom }) =>
    (hasmarginbottom && `${marginbottom}px`) || `0px`};
`;

const DolapoTextTag2 = styled.p<DolapoTextStyleProp>`
  font-family: "InriaSans";
  display: ${({ displayBlock }) =>
    !displayBlock || displayBlock !== undefined ? `inline` : `block`};
  text-align: ${({ textalign }) =>
    (textalign === TextAlign.CENTER && `center`) ||
    (textalign === TextAlign.RIGHT && `right`) ||
    (textalign === TextAlign.LEFT && `left`)};
  font-weight: ${({ fontWeight }) =>
    (fontWeight === TextStyle.NORMAL && `normal`) ||
    (fontWeight === TextStyle.SEMIBOLD && `500`) ||
    (fontWeight === TextStyle.BOLD && `bold`)};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => `${color}`};
  margin-bottom: ${({ hasmarginbottom, marginbottom }) =>
    (hasmarginbottom && `${marginbottom}px`) || `0px`};
`;

export function DolapoTextWithoutUnderline(props: DolapoTextProp) {
  return (
    <DolapoTextTag
      size={props.size}
      displayBlock={props.displayBlock}
      textalign={props.textalign}
      fontWeight={props.fontWeight}
      color={props.color}
      marginbottom={props.marginBottom}
      hasmarginbottom={props.hasMarginBottom}
    >
      {props.content}
    </DolapoTextTag>
  );
}

// export default DolapoTextWithoutUnderline;

export function DolapoTextWithoutUnderline2(props: DolapoTextProp) {
  console.log("props", props);
  return (
    <DolapoTextTag2
      size={props.size}
      displayBlock={props.displayBlock}
      textalign={props.textalign}
      fontWeight={props.fontWeight}
      color={props.color}
      marginbottom={props.marginBottom}
      hasmarginbottom={props.hasMarginBottom}
    >
      {props.content}
    </DolapoTextTag2>
  );
}
