import React from "react";
import Skill from "../components/Skill";
import styled from "styled-components";
import { SkillItems } from "../props/interface";
import DolapoText from "../components/DolapoText";
import { TextAlign, TextStyle } from "../props/enums";

const SkillsCanvas = styled.div`
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  padding: 20px 100px 80px 215px;
`;

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: center;
`;

function Skills({ items }: SkillItems) {
  return (
    <SkillsCanvas id="skills">
      <DolapoText
        fontWeight={TextStyle.BOLD}
        textalign={TextAlign.CENTER}
        size={28}
        color="#000000"
        hasMarginBottom={false}
        marginBottom={20}
        content="Skills"
        underline={true}
      />

      <SkillsContainer>
        {items.map((item) => {
          return (
            <Skill key={item.title} title={item.title} skills={item.skills} />
          );
        })}
      </SkillsContainer>
    </SkillsCanvas>
  );
}

export default Skills;
