import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { MenuItemProp, Props } from "../props/interface";
import { TextAlign, TextStyle } from "../props/enums";
import { DolapoTextWithoutUnderline } from "./DolapoTextWithoutUnderline";
import "../asset/fonts/InriaSans-Regular.ttf";
import "../asset/fonts/Inter-Regular.ttf";
import "../asset/fonts/Jost-Light.ttf";
import { HomeImageProp } from "../pages/Home";
import myLogo from "../asset/image/dolapo-logo.png";
import { scroller } from "react-scroll";

const Menu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 1rem;
  margin-top: 45px;
`;

const MenuNav = styled.ul`
  list-style: none;
  text-align: center;
  margin-left: 500px;
  margin-right: 140px;

  @media (max-width: 1000px) {
    margin-left: 200px;
  }
`;

const MenuItem = styled.li<MenuItemProp>`
  margin: 0 1rem;
  display: inline;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const DolapoLogo = styled.img<HomeImageProp>`
  margin-left: 40px;
  width: 65px;
  height: 58px;

  @media (max-width: 1000px) {
    margin-left: 55px;
  }
`;

// const Logo = styled(DolapoTextWithoutUnderline)`
//   margin-left: 40px;

//   @media (max-width: 1000px) {
//     margin-left: 55px;
//   }
// `;

const MenuNavIndicator = styled.div<Props>`
  position: absolute;
  top: 80px;
  left: ${({ left }) => `${left}px`};
  width: ${({ width }) => `${width}px`};
  height: 10px;
  transition: all.3s ease-in-out;

  @media (max-width: 1000px) {
    top: 97px;
  }
`;

const MenuNavIndicatorImg = styled.img`
  height: 100%;
  width: 100%;
`;

const MenuBar = () => {
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const navRef = useRef<HTMLDivElement>(null);
  const homeRef = useRef<HTMLDivElement>(null);
  var hasCalledHomeRect = true;

  useEffect(() => {
    const homeNavLeft = homeRef.current?.getBoundingClientRect().left;
    const homeNavItemWidth = homeRef.current?.getBoundingClientRect().width;
    setIndicatorPosition(homeNavLeft!);
    setIndicatorWidth(homeNavItemWidth!);
  }, [hasCalledHomeRect]);

  window.addEventListener("resize", (event) => {
    const homeNavLeft = homeRef.current?.getBoundingClientRect().left;
    const homeNavItemWidth = homeRef.current?.getBoundingClientRect().width;
    setIndicatorPosition(homeNavLeft!);
    setIndicatorWidth(homeNavItemWidth!);
  });

  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    hasCalledHomeRect = true;
    console.log(event);
    const node = event.target as HTMLElement;
    const linkLeft = node.getBoundingClientRect().left;
    if (navRef != null) {
      const navLeft = navRef.current?.getBoundingClientRect().left;
      const singleLinkLeft = linkLeft - navLeft!;
      const linkWidth = node.getBoundingClientRect().width;
      const singleLinkWidth = linkWidth;
      setIndicatorPosition(singleLinkLeft);
      setIndicatorWidth(singleLinkWidth);
    }
  };

  const handleSkillClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    scrollToSection("skills");
    hasCalledHomeRect = true;
    console.log(event);
    const node = event.target as HTMLElement;
    const linkLeft = node.getBoundingClientRect().left;
    if (navRef != null) {
      const navLeft = navRef.current?.getBoundingClientRect().left;
      const singleLinkLeft = linkLeft - navLeft!;
      const linkWidth = node.getBoundingClientRect().width;
      const singleLinkWidth = linkWidth;
      setIndicatorPosition(singleLinkLeft);
      setIndicatorWidth(singleLinkWidth);
    }
  };

  const scrollToSection = (section: string) => {
    scroller.scrollTo(section, {
      duration: 800,
      smooth: "easeInOutQuart",
      offset: -50,
    });
  };

  return (
    <Menu ref={navRef}>
      <DolapoLogo src={myLogo} />
      {/* <Logo
        content="Dolapo Olakanmi"
        size={32}
        color="#2b2b2b"
        marginBottom={0}
        underline={false}
        fontWeight={TextStyle.BOLD}
        hasMarginBottom={false}
        textalign={TextAlign.CENTER}
      /> */}
      <MenuNav>
        <MenuItem ref={homeRef} onClick={handleClick}>
          <DolapoTextWithoutUnderline
            content="Home"
            size={18}
            color="#2b2b2b"
            marginBottom={0}
            underline={false}
            fontWeight={TextStyle.NORMAL}
            hasMarginBottom={false}
            textalign={TextAlign.RIGHT}
          />
        </MenuItem>
        <MenuItem onClick={handleSkillClick}>
          <DolapoTextWithoutUnderline
            content="Skills"
            size={18}
            color="#2b2b2b"
            marginBottom={0}
            underline={false}
            fontWeight={TextStyle.NORMAL}
            hasMarginBottom={false}
            textalign={TextAlign.CENTER}
          />
        </MenuItem>
        <MenuItem onClick={handleClick}>
          <DolapoTextWithoutUnderline
            content="Projects"
            size={18}
            color="#2b2b2b"
            marginBottom={0}
            underline={false}
            fontWeight={TextStyle.NORMAL}
            hasMarginBottom={false}
            textalign={TextAlign.CENTER}
          />
        </MenuItem>
        <MenuItem onClick={handleClick}>
          <DolapoTextWithoutUnderline
            content="Blogs"
            size={18}
            color="#2b2b2b"
            marginBottom={0}
            underline={false}
            fontWeight={TextStyle.NORMAL}
            hasMarginBottom={false}
            textalign={TextAlign.CENTER}
          />
        </MenuItem>
        <MenuNavIndicator left={indicatorPosition} width={indicatorWidth}>
          <MenuNavIndicatorImg
            src={require("../asset/image/home-menu-indicator.png")}
          />
        </MenuNavIndicator>
      </MenuNav>
    </Menu>
  );
};

export default MenuBar;
