import { Slab } from "react-loading-indicators";
import styled from "styled-components";

const LoadingIndicatorContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  align-content: center;
  justify-content: center;
  align-item: center;
  background-color: #ededed;
  text-align: center;
`;

function LoadingIndicator() {
  return (
    <LoadingIndicatorContainer>
      <Slab
        color="#bbb06e"
        size="large"
        text="Setting up..."
        textColor="#ffffff4d"
      />
    </LoadingIndicatorContainer>
  );
}

export default LoadingIndicator;
